/* ------------------------------------
	#VARIABLES
--------------------------------------- */
	
	$login-white: #FEFEFE;
	$border-color: #DDDDDD;



/* ------------------------------------
	#MIXINS
--------------------------------------- */
	
	@mixin bg-gradient($from, $to) {
		background-color:$from;
		background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
		background: -webkit-linear-gradient(top, $from, $to);
		background: -moz-linear-gradient(top, $from, $to);
		background: -ms-linear-gradient(top, $from, $to);
		background: -o-linear-gradient(top, $from, $to);
		background-image: -ms-linear-gradient(top, $from 0%, $to 100%);
	}



/* ------------------------------------
	#CUSTOM STYLING
--------------------------------------- */
	
	* {
		box-sizing: border-box;
	}

	body.login {
		background: #9fbcca url('../../../images/login/gough-login-bg.jpg');
		background-repeat: no-repeat;
		background-position: center 80%;
		background-size:cover;
		
	}

	#loginform, #lostpasswordform {
		max-width: 320px;
		position: absolute;
		top: 50%;
		margin-top: 0px;
		transform: translateY(-50%);
		
		&:before {
			display: block;
			content: "";
			width: 110px;
			height: 110px;
			background: url('../../../images/login/gough-login-logo.png') no-repeat;
			background-size: 110px;
			margin: 0 auto;
		}
	}

	.login {
		h1{ display: none; }
		
		#login_error, .message {
			position: relative;
			z-index: 10;
		}

		label {
			font-size: 12px;
			color: #555555;
		}

		input {
			-webkit-border-radius: 4px;
		}

		input[type="text"], input[type="password"] {
			border-color: $border-color;
			background-color: $login-white;
		}

		.button-primary {
			margin: 25px 0;
			width: 100% !important;
			border: 1px solid #0d9ed9;
			@include bg-gradient(#17a8e3, #17a8e3);
		
			&:hover {
				border: 1px solid #0d9ed9;
				@include bg-gradient(#17a8e3, #0d9ed9);
			}
		
			&:active {
				border: 1px solid #0d9ed9;
				@include bg-gradient(#0d9ed9, #17a8e3);
			}
		}
	}



/* ------------------------------------
	#WORDPRESS OVERRIDES
--------------------------------------- */

	#login {
		width: 100%;
		padding: 0;
	}

	p#backtoblog, p#nav {
		display: none;
	}

/* -----------------------------------------
	#MEDIA QUERY - Mobile Devices (Portrait)
-------------------------------------------- */

	@media (max-width: 768px) {
		#login {
			width: 100%;
		}

		#loginform, #lostpasswordform {
			box-shadow: none;
			width: 80%;
			margin: 0 auto;
			left: 0;
			right: 0;
			top: 60%;
			transform: translateY(-60%);
		}

		body.login {
			background-position: center center;
		}

		.forgetmenot {
			display: block;
		}
	}

/* -----------------------------------------
	#MEDIA QUERY - Mobile Devices (Mobile)
-------------------------------------------- */

	@media screen and (orientation:landscape) { 
		@media (max-width: 768px) {
			.forgetmenot {
				display: none;
			}

			#loginform {
				height: 100%;
				padding: 10px 25px;

				&:before {
					width: 80px;
					height: 80px;
					background-size: 80px;
				}
			}
		}
	}